import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FaPlus } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import ReactGA from "react-ga4";
import './App.css';
import { Word, User, WordSuggestion } from './types';
import { login, logout, isTokenExpired, refreshToken } from './auth';
import QuickAddForm from './components/QuickAddForm';
import axiosInstance from './axiosConfig';
import Progress from './components/Progress';
import WordList from './components/WordList';
import NoWordsToReview from './components/NoWordsToReview';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import SentencesSection from './components/SentencesSection';
import ConfirmationMessage from './components/ConfirmationMessage';
// import * as amplitude from '@amplitude/analytics-browser';
import ReviewCard from './components/ReviewCard';
import PullToRefresh from './components/PullToRefresh';
import WordOfTheDay from './components/WordOfTheDay';

function App() {
  const API_BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://buildie.ru/wordef/api'
    : 'http://localhost:5000/api';

  // State variables
  const [words, setWords] = useState<Word[]>([]);
  const [newWord, setNewWord] = useState<{
    word: string;
    part_of_speech: string;
    definition: string;
    example: string;
    pronunciation: string | null;
  }>({
    word: '',
    part_of_speech: '',
    definition: '',
    example: '',
    pronunciation: null
  });

  const [user, setUser] = useState<User | null>(null);
  const [authForm, setAuthForm] = useState<User>({ email: '', password: '' });
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [wordsToReview, setWordsToReview] = useState<Word[]>([]);
  const [currentReviewWord, setCurrentReviewWord] = useState<Word | null>(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const [wordSuggestions, setWordSuggestions] = useState<WordSuggestion[]>([]);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null);
  const [examples, setExamples] = useState<string[]>([]);
  const wordInputRef = useRef<HTMLInputElement>(null);
  const [totalWords, setTotalWords] = useState<number>(0);
  const [streakDays, setStreakDays] = useState<number>(0);
  const [exampleGenerationError, setExampleGenerationError] = useState<string | null>(null);
  const [currentView, setCurrentView] = useState(() => {
    return localStorage.getItem('currentView') || 'home';
  });
  const [editingWordId, setEditingWordId] = useState<number | null>(null);
  const [wordOfTheDay, setWordOfTheDay] = useState<Word | null>(null);

  const navigate = useNavigate();

  // Helper function to debounce another function
  const debounce = <T extends (...args: any[]) => any>(func: T, delay: number) => {
    let debounceTimer: NodeJS.Timeout;
    return (...args: Parameters<T>): void => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  // API calls
  const fetchWords = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/words');
      setWords(response.data);
    } catch (e) {
      console.error("There was a problem with the fetch operation:", e);
      setError("Failed to fetch words. Please try again.");
    }
  }, []);

  const fetchWordsToReview = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/words/due-for-review');
      const data: Word[] = response.data;
      setWordsToReview(data);
      if (data.length > 0) {
        setCurrentReviewWord(data[0]);
      }
    } catch (e) {
      console.error("Error fetching words for review:", e);
      setError("Failed to fetch words for review. Please try again.");
    }
  }, []);

  const generateExamples = useCallback(async (word: string, partOfSpeech: string, definition: string): Promise<string[]> => {
    try {
      const response = await axiosInstance.post('/generate-examples', { word, part_of_speech: partOfSpeech, definition });
      return response.data.examples;
    } catch (error) {
      console.error('Error generating examples:', error);
      throw error;
    }
  }, []);

  const handleAddWord = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newWord.word || !newWord.part_of_speech || !newWord.definition) return;

    try {
      // Generate examples if not provided
      if (!newWord.example) {
        const generatedExamples = await generateExamples(newWord.word, newWord.part_of_speech, newWord.definition);
        setExamples(generatedExamples);
        if (generatedExamples.length > 0) {
          setNewWord(prev => ({ ...prev, example: generatedExamples[0] }));
        }
      }

      const response = await axiosInstance.post('/words', {
        ...newWord,
        pronunciation: null
      });

      const addedWord = response.data;

      setNewWord({ word: '', part_of_speech: '', definition: '', example: '', pronunciation: null });
      setExamples([]);
      fetchWords();
      setShowQuickAdd(false);
      setConfirmationMessage(`Word "${addedWord.word}" added successfully!`);
      setTimeout(() => setConfirmationMessage(null), 3000);
      setTotalWords(prevCount => prevCount + 1);
      ReactGA.event('add_word');
      // amplitude.track('add_word');
    } catch (error) {
      console.error('Error adding word:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.error || "Failed to add word. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  }, [fetchWords, generateExamples, newWord]);

  const handleRegister = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(authForm),
      });
      if (!response.ok) {
        throw new Error('Registration failed');
      }
      await response.json();
      alert('Registration successful. Please log in.');
      setIsRegistering(false);
    } catch (e) {
      console.error('Error registering:', e);
      setError("Registration failed. Please try again.");
    }
  }, [API_BASE_URL, authForm]);

  const handleLogin = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      if (!authForm.email || !authForm.password) {
        throw new Error("Email and password are required");
      }
      const userData = await login(authForm.email, authForm.password);
      setUser({ email: userData.email });
      await fetchWords();
      await fetchWordsToReview();
    } catch (error) {
      console.error('Error logging in:', error);
      setError("Login failed. Please check your credentials and try again.");
    }
  }, [authForm, fetchWords, fetchWordsToReview]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      setUser(null);
      setWords([]);
      setWordsToReview([]);
      setCurrentReviewWord(null);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
      setError("Logout failed. Please try again.");
    }
  }, [navigate]);

  const handleUpdateWord = useCallback(async (updatedWord: Word) => {
    try {
      const response = await axiosInstance.put(`/words/${updatedWord.id}`, updatedWord);
      setWords(words.map(w => w.id === updatedWord.id ? response.data : w));
      setEditingWordId(null);
      ReactGA.event('update_word');
      // amplitude.track('update_word');
    } catch (e) {
      console.error('Error updating word:', e);
      setError("Failed to update word. Please try again.");
    }
  }, [words]);

  const handleEditWord = useCallback((word: Word | null) => {
    setEditingWordId(word ? word.id : null);
  }, []);

  const handleDeleteWord = useCallback(async (id: number) => {
    try {
      await axiosInstance.delete(`/words/${id}`);
      fetchWords();
      ReactGA.event('delete_word');
      // amplitude.track('delete_word');
    } catch (e) {
      console.error('Error deleting word:', e);
      setError("Failed to delete word. Please try again.");
    }
  }, [fetchWords]);

  const handleReview = useCallback(async (difficulty: 'hard' | 'medium' | 'easy') => {
    if (!currentReviewWord) return;

    try {
      await axiosInstance.post(`/words/${currentReviewWord.id}/review`, { difficulty });
      // amplitude.track('word_review', { difficulty: difficulty });
      setWordsToReview(prev => prev.filter(w => w.id !== currentReviewWord.id));
      if (wordsToReview.length > 1) {
        setCurrentReviewWord(wordsToReview[1]);
        setShowAnswer(false);
      } else {
        setCurrentReviewWord(null);
      }
    } catch (e) {
      console.error("Error submitting review:", e);
      setError("Failed to submit review. Please try again.");
    }
  }, [currentReviewWord, wordsToReview]);

  const toggleShowAnswer = useCallback(() => setShowAnswer(prev => !prev), []);

  const toggleQuickAdd = useCallback(() => {
    setShowQuickAdd(prev => !prev);
    if (!showQuickAdd) {
      setNewWord({
        word: '',
        part_of_speech: '',
        definition: '',
        example: '',
        pronunciation: null
      });
      setExamples([]);
      setTimeout(() => wordInputRef.current?.focus(), 100);
    }
  }, [showQuickAdd]);

  // Debounced version of fetchWordSuggestions
  const debouncedFetchWordSuggestions = useCallback(
    debounce(async (input: string) => {
      if (input.length < 2) return;
      try {
        const response = await axios.get(`https://api.datamuse.com/words?sp=${input}*&md=dprf&ipa=1`);

        const filteredAndSortedData = response.data
          .filter((item: any) => item.defs && item.defs.length > 0)
          .sort((a: any, b: any) => {
            const fA = parseFloat(a.tags.find((tag: string) => tag.startsWith('f:'))?.slice(2)) || 0;
            const fB = parseFloat(b.tags.find((tag: string) => tag.startsWith('f:'))?.slice(2)) || 0;
            return fB - fA;
          });

        const groupedSuggestions = filteredAndSortedData.slice(0, 5).map((item: any) => {
          const partOfSpeechMap: { [key: string]: string } = {
            'n': 'noun',
            'v': 'verb',
            'adj': 'adjective',
            'adv': 'adverb',
          };

          const definitionsByPos: { [key: string]: string[] } = {};
          item.defs.forEach((def: string) => {
            const [pos, definition] = def.split('\t');
            if (!definitionsByPos[pos]) {
              definitionsByPos[pos] = [];
            }
            definitionsByPos[pos].push(definition);
          });

          const posOrder = ['n', 'adj', 'v', 'adv'];
          const sortedPos = Object.keys(definitionsByPos).sort((a, b) =>
            posOrder.indexOf(a) - posOrder.indexOf(b)
          );

          return {
            word: item.word,
            pronunciation: item.tags ? item.tags.find((tag: string) => tag.startsWith('ipa_pron:'))?.slice(9) : null,
            frequency: parseFloat(item.tags.find((tag: string) => tag.startsWith('f:'))?.slice(2)) || 0,
            definitions: sortedPos.map(pos => ({
              part_of_speech: partOfSpeechMap[pos] || pos,
              definitions: definitionsByPos[pos]
            }))
          };
        });

        setWordSuggestions(groupedSuggestions);
      } catch (error) {
        console.error('Error fetching word suggestions:', error);
      }
    }, 300),
    []
  );

  // Wrapper function to match the expected type
  const fetchWordSuggestions = useCallback(async (input: string): Promise<void> => {
    debouncedFetchWordSuggestions(input);
  }, [debouncedFetchWordSuggestions]);

  const fetchUserStats = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/user/stats');
      setTotalWords(response.data.totalWords);
      setStreakDays(response.data.streakDays);
    } catch (error) {
      console.error('Error fetching user stats:', error);
    }
  }, []);

  const fetchWordOfTheDay = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/word-of-the-day');
      setWordOfTheDay(response.data);
    } catch (error) {
      console.error('Error fetching word of the day:', error);
      setError("Failed to fetch word of the day. Please try again.");
    }
  }, []);

  // Render functions
  const renderAuthForm = useCallback(() => (
    <div className="auth-page">
      <div className="auth-container">
        <div className="auth-column">
          <ServiceDescription />
        </div>
        <div className="auth-column">
          <div className="auth-form">
            <h2>{isRegistering ? 'Register' : 'Login'}</h2>
            {error && (
              <div className="error-message">
                <p>{error}</p>
                <button onClick={() => setError(null)}>Dismiss</button>
              </div>
            )}
            <form onSubmit={isRegistering ? handleRegister : handleLogin}>
              <input
                type="email"
                placeholder="Email"
                value={authForm.email}
                onChange={e => setAuthForm({ ...authForm, email: e.target.value })}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={authForm.password}
                onChange={e => setAuthForm({ ...authForm, password: e.target.value })}
                required
              />
              <button type="submit">{isRegistering ? 'Register' : 'Login'}</button>
            </form>
            <button onClick={() => setIsRegistering(prev => !prev)} className="switch-auth-mode">
              {isRegistering ? 'Switch to Login' : 'Switch to Register'}
            </button>
          </div>
        </div>
      </div>
    </div>
  ), [authForm, error, handleLogin, handleRegister, isRegistering]);

  const renderMainContent = useCallback((view: string) => {
    switch (view) {
      case 'home':
        return (
          <>
            {currentReviewWord ? (
              <ReviewCard
                currentReviewWord={currentReviewWord}
                showAnswer={showAnswer}
                toggleShowAnswer={toggleShowAnswer}
                handleReview={handleReview}
              />
            ) : wordsToReview.length === 0 ? (
              <>
                {wordOfTheDay && <WordOfTheDay word={wordOfTheDay} />}
                <NoWordsToReview
                  totalWords={totalWords}
                  streakDays={streakDays}
                  setCurrentView={setCurrentView}
                />
              </>
            ) : null}
          </>
        );
      case 'wordList':
        return (
          <WordList
            words={words}
            onEdit={handleEditWord}
            onDelete={handleDeleteWord}
            onSave={handleUpdateWord}
            editingWordId={editingWordId}
          />
        );
      case 'sentences':
        return <SentencesSection />;
      case 'progress':
        return <Progress />;
      default:
        return null;
    }
  }, [
    currentReviewWord,
    editingWordId,
    handleEditWord,
    handleDeleteWord,
    handleReview,
    handleUpdateWord,
    showAnswer,
    streakDays,
    toggleShowAnswer,
    totalWords,
    words,
    wordsToReview.length,
    wordOfTheDay
  ]);

  // Effects
  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('accessToken');
      const refreshTokenValue = localStorage.getItem('refreshToken');
      const email = localStorage.getItem('email');

      if (token && refreshTokenValue && email) {
        if (!isTokenExpired()) {
          setUser({ email });
        } else {
          try {
            await refreshToken();
            setUser({ email });
          } catch (error) {
            console.error('Token refresh failed:', error);
            handleLogout();
          }
        }
      } else {
        setUser(null);
      }
    };

    checkAuthStatus();
  }, [handleLogout]);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          await Promise.all([
            fetchWords(),
            fetchWordsToReview(),
            fetchUserStats(),
            fetchWordOfTheDay()
          ]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [user, fetchWords, fetchWordsToReview, fetchUserStats, fetchWordOfTheDay]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && showQuickAdd) {
        setShowQuickAdd(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showQuickAdd]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (showQuickAdd && !target.closest('.quick-add-form')) {
        setShowQuickAdd(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showQuickAdd]);

  useEffect(() => {
    localStorage.setItem('currentView', currentView);
  }, [currentView]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'w') {
        toggleQuickAdd();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [toggleQuickAdd]);

  useEffect(() => {
    ReactGA.initialize("G-4ZWJ6VHN52");
    // amplitude.init('bBWSdBgnGJcV-mnKU3sYCqJDSi4wukJH', {
    //  defaultTracking: false,
    // });
  }, []);

  useEffect(() => {
    if (showQuickAdd) {
      document.body.classList.add('quick-add-open');
    } else {
      document.body.classList.remove('quick-add-open');
    }
  }, [showQuickAdd]);

  //Components
  const Footer: React.FC = useCallback(() => (
    <footer className="app-footer">
      <p>&copy; 2024 Wordef. All rights reserved.</p>
    </footer>
  ), []);

  const ServiceDescription: React.FC = useCallback(() => (
    <div className="service-description">
      <h2>Welcome to Wordef</h2>
      <p>Wordef is your personal vocabulary builder. Learn new words, review them regularly, and expand your language skills effortlessly.</p>
      <ul>
        <li>Add new words to your personal dictionary</li>
        <li>Review words with spaced repetition</li>
        <li>Track your progress and improve your vocabulary</li>
      </ul>
    </div>
  ), []);

  const handleRefresh = useCallback(async () => {
    if (user) {
      try {
        await Promise.all([
          fetchWords(),
          fetchWordsToReview(),
          fetchUserStats()
        ]);
      } catch (error) {
        console.error('Error refreshing data:', error);
        setError("Failed to refresh data. Please try again.");
      }
    }
  }, [user, fetchWords, fetchWordsToReview, fetchUserStats]);

  // Main render function
  return (
    <div className="App">
      <Helmet>
        <title>Wordef - Word Learning App</title>
      </Helmet>

      <PullToRefresh onRefresh={handleRefresh}>
        <DesktopHeader handleLogout={handleLogout} />
        <MobileHeader handleLogout={handleLogout} />
        <ConfirmationMessage message={confirmationMessage} />

        <main className="app-main">
          <Routes>
            <Route path="/" element={!user ? renderAuthForm() : <Navigate to="/home" />} />
            <Route path="/home" element={user ? renderMainContent('home') : <Navigate to="/" />} />
            <Route path="/word-list" element={user ? renderMainContent('wordList') : <Navigate to="/" />} />
            <Route path="/sentences" element={user ? renderMainContent('sentences') : <Navigate to="/" />} />
            <Route path="/progress" element={user ? renderMainContent('progress') : <Navigate to="/" />} />
          </Routes>
        </main>
      </PullToRefresh>

      <AnimatePresence>
        {showQuickAdd && (
          <motion.div
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <QuickAddForm
              newWord={newWord}
              setNewWord={setNewWord}
              wordSuggestions={wordSuggestions}
              setWordSuggestions={setWordSuggestions}
              fetchWordSuggestions={fetchWordSuggestions}
              handleAddWord={handleAddWord}
              setShowQuickAdd={setShowQuickAdd}
              generateExamples={generateExamples}
              examples={examples}
              setExamples={setExamples}
              exampleGenerationError={exampleGenerationError}
              setExampleGenerationError={setExampleGenerationError}
              onAddWord={handleAddWord}
              onClose={() => setShowQuickAdd(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.button
        className="floating-add-button"
        onClick={toggleQuickAdd}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <FaPlus />
        <span>Add Word</span>
      </motion.button>
      <Footer />
    </div>
  );
}

export default App;